<template>
  <div class="div-clas mb-5" style="margin-top:-5rem !important;">
      <div class="bg-pixel img-fluid">
        <div class="container-fluid margin-text-titulo-chatbot">
          <div class="row" style="margin-top:6%;">
            <img class="img-header-25k-movil" style="margin-top:8rem !important;" src="../assets/Nosotros/icono_principal.png" alt="">
            <div class="col-md-5 offset-md-1">
              <h1 class="text-titulo-header-pixel animate__animated  animate__bounceInDown animate__fastanimate__fast "><b>CAMPAÑAS DE VENTA EN FACEBOOK</b></h1>
              <div class="tamaño-div-chatbot"><p class="text-header-chatbot" data-aos="fade-up" >Conoce más de nuestro método al diseñar las campañas ideales y específicas para las necesidades de tu negocio; así como los grandes resultados que podemos lograr con estas estrategias.</p></div>
              <p class="text-chatbot" data-aos="fade-up" >*Servicio no disponible en modalidad individual. Se trabaja en cualquiera de nuestros paquetes. </p>
            
            </div>
            <div class="col-md-6">
              <img class="img-header-25k " src="../assets/Nosotros/icono_principal.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <img class="img-fluid img-datos-movil " data-aos="fade-up"  src="../assets/img-techsoft/crea-anuncios.png" alt="">
          <div class="col-md-5 offset-md-1 text-align-l-chatbot">
              <h1 class="text-titulo-chatbot" data-aos="fade-up" ><b>Creación de anuncios</b></h1>
              <p class="p-chatbot mt-4" data-aos="fade-up" >Con esta herramienta, podemos medir con precisión qué usuario de facebook y/o instagram entraron a tu página web y vieron algún producto o lo agregaron a su carrito. La finalidad de esto es poder hacer la magia de las campañas de retargeting.</p>
          </div>
          <div class="col-md-4 offset-md-1 " data-aos="fade-up" data-aos-delay="200"  data-aos-easing="linear">
            <div class=""><img class="img-fluid img-datos" src="../assets/img-techsoft/crea-anuncios.png" alt=""></div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-3">
        <div class="row">
          <div class="col-md-4 offset-md-1">
            <img class="img-fluid img-venta-movil" data-aos="fade-up" data-aos-easing="linear" src="../assets/img-techsoft/analisis-datos.png" alt="">
            <div class=""><img class="img-fluid img-venta" data-aos="fade-up" data-aos-delay="200"  data-aos-easing="linear" src="../assets/img-techsoft/analisis-datos.png" alt=""></div>
          </div>
          <div class="col-md-5 offset-md-1 text-align-l-chatbot">
              <h1 class="text-titulo-chatbot" data-aos="fade-up"><b>Análisis de datos</b></h1>
              <p class="p-chatbot mt-4" data-aos="fade-up">Estas campañas se basan en la información del pixel para lanzar anuncios que le muestren a los usuarios que entraron a tu página, los productos que buscaron o dejaron en el carrito con la finalidad de concretar la venta.</p>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <img class="img-fluid img-datos-movil" data-aos="fade-up" src="../assets/img-techsoft/automatizacion.png" alt="">
          <div class="col-md-5 offset-md-1 text-align-l-chatbot">
              <h1 class="text-titulo-chatbot" data-aos="fade-up"><b>Automatización</b></h1>
              <p class="p-chatbot mt-4" data-aos="fade-up">Al dejar automatizados los anuncios logramos dar un uso eficiente al presupuesto evitando que se malgaste en anuncios que no están dando los resultados esperados</p>
          </div>
          <div class="col-md-4 offset-md-1">
            <div class=""><img class="img-fluid img-datos" data-aos="fade-up" data-aos-delay="200"  data-aos-easing="linear" src="../assets/img-techsoft/automatizacion.png" alt=""></div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-3">
        <div class="row">
          <div class="col-md-4 offset-md-1">
            <img class="img-fluid img-venta-movil" data-aos="fade-up" src="../assets/img-techsoft/diseño-texto.png" alt="">
            <div class=""><img class="img-fluid img-venta" data-aos="fade-up" data-aos-delay="200"  data-aos-easing="linear" src="../assets/img-techsoft/diseño-texto.png" alt=""></div>
          </div>
          <div class="col-md-5 offset-md-1 text-align-l-chatbot">
              <h1 class="text-titulo-chatbot" data-aos="fade-up"><b>Diseño de textos</b></h1>
              <p class="p-chatbot mt-4" data-aos="fade-up" >Usamos softwares con inteligencia artificial que nos ayudan a predecir qué tipo de textos son los más adecuados y de esta manera incrementar la efectividad de los anuncios.</p>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <img class="img-fluid img-datos-movil" src="../assets/img-techsoft/diseño anuncios.png" alt="">
          <div class="col-md-5 offset-md-1 text-align-l-chatbot">
              <h1 class="text-titulo-chatbot" data-aos="fade-up" data-aos-delay="200"  data-aos-easing="linear"><b>Diseño de anuncios</b></h1>
              <p class="p-chatbot mt-4" data-aos="fade-up" data-aos-delay="200"  data-aos-easing="linear">Con la finalidad de ir en mejora constante; nos basamos en los anuncios que mejor hayan funcionado, para diseñar el contenido creativo para los nuevos anuncios.</p>
          </div>
          <div class="col-md-4 offset-md-1 " data-aos="fade-up">
            <div class=""><img class="img-fluid img-datos" src="../assets/img-techsoft/diseño anuncios.png" alt=""></div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-5">
        <div class="row back-color">
          <div class="col-md-12">
            <div class="align">
              <h1 class="titulo-w " data-aos="fade-up"><b> ¿Qué necesitamos para empezar?</b></h1>
              <p class="text-p-camp" data-aos="fade-up">Necesitamos el contenido de los productos, conocer el cliente o modelos/personas usando actualmente el producto, para posteriormente realizar la edición y publicitarlo</p>
              <p class="mb-0 mt-5 text-chatbot2" style="color:white;" data-aos="fade-up"><b> *Servicio no disponible en modalidad individual. Se trabaja en cualquiera de nuestros paquetes. </b></p> <br>
            </div>
            <div>
        <b-button pill variant="outline-light" size="lg" class="mb-4 shadow" >
          <span class="h6 mr-2 font-weight-bold">Contactenos</span>
          <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
        </b-button>
      </div>
          </div>
          
        </div>
        
      </div>
      
  </div> 
</template>

<script>
export default {

}
</script>

<style>
.pill {
  
  
  padding: 10px 20px;
  text-align: center;
  
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
}
.text-p-camp{
  color: rgb(251, 251, 251);
  font-size: 25px;
  width: 80%;
  margin-left: 10%;
}
.align{
  text-align: center;
}
.back-color{
  background-color: #168eff;
}
.titulo-w{
  color: rgb(255, 255, 255);
  font-size: 45px;
  margin-top: 3%;
  
}
.img-venta{
  display: inline;
  margin-top: 5%;
}
.img-venta-movil{
   display: none;
}
.img-datos{
  display: inline;
  margin-top: 5%;
}
.img-datos-movil{
  display: none;
}
.img-header-25k-movil{
  display: none;
}
.img-header-25k{
  display: inline;
}
.f-chatbot{
  height: 500px;
  margin-bottom: 20%;
  margin-top: -1%;
}

.bg-pixel { 
  /* The image used */
  background-image: url("../assets/Inicio/principal.png");
  margin-top: 5%;
  height: 130%; 


  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div1-25k{
  background-image: url("../assets/Inicio/creacion_pagina_web.png");
   width: 100%;
  height: 100%;

}
.div2-25k{
  background-image: url("../assets/Inicio/desarrollo_plataformas.png");
  width: 100%;
  height: 100%;


}
.div3-25k{
  background-image: url("../assets/The_25k_Club/sitio_web.png");
  width: 100%;
  height: 100%;


}
.div4-25k{
  background-image: url("../assets/The_25k_Club/redes_sociales.png");
  width: 100%;
  height: 100%;


}
.div5-25k{
  background-image: url("../assets/Inicio/campanas_fb_ga.png");
  width: 100%;
  height: 100%;


}
.div6-25k{
  background-image: url("../assets/The_25k_Club/sms_masivos.png");
  width: 100%;
  height: 100%;


}


@-webkit-keyframes mover2 {
    1% { transform: translateX(1px); }
    50% { transform: translateY(-15px); }
}

@media only screen and (max-width: 770px) {
  .text-p-camp{
  font-size: 1.2rem !important;
    width: 100%;
    text-align: justify ;
    margin-left: 0px;
    
}
  .titulo-w{
  color: rgb(255, 255, 255);
  font-size: 1.6rem !important;
  
}
  .p-chatbot{
    font-size: 1.2rem !important;
    width: 100%;
  }
  .text-titulo-chatbot{
  font-size: 1.6rem !important;
    text-align: center;
}
  
  .text-header-chatbot{
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: justify;
    padding: 5%;
    margin-top: -5%;
    
    
  }
  .img-header-25k{
  display: none;
}
  .img-header-25k-movil{
  display: inline;
    width: 40%;
    margin-top: 15%;
    margin-bottom: 10%;
    margin-left: 30%;}
.fondo-web{
  display: none;
}
.fondo-movil{
  display: inline;
  margin-right: 0%;
}
.text-titulo-header-pixel{
    color: rgb(255, 255, 255);
    font-size: 2.1rem !important;
    width: 80%;
    margin-left: 8%;
    text-align: center;
  }
  .bg-pixel { 
    height: 140%;
  }
  .text-chatbot{
  font-size: 0.8rem !important;
  margin-top: 0px;
  margin-bottom: 50;
  margin-left: 5%;
}
.img-datos-movil{
  display: inline;
    width: 80%;
    margin-top: 5%;
    margin-bottom: 5%; 
    margin-left: 5%;
}
.img-datos{
  display: none;
}
.img-venta{
  display: none;
}
.img-venta-movil{
  display: inline;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%; 
    margin-left: 0%;
}
.text-chatbot2{
  font-size: 0.8rem;
  margin-top: 0px;
  margin-bottom: 50;
  margin-right: 5%;
  margin-left: 5%;
}
}
@media only screen and (max-width: 800px) and (min-width: 780px) {

}

</style>